import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableCellProps,
} from "@mui/material";

const cellStyle = {
  border: "1px solid rgba(0, 0, 0, 0.12)",
};

const tableHeadCellStyle = {
  ...cellStyle,
  fontWeight: 600,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

type CellType = {
  id: string;
  text: string;
  linkTo?: string;
  sx?: object;
  align?: TableCellProps["align"];
  colSpan?: number;
};

type Props = {
  title?: string;
  headers?: {
    text: string;
    colSpan?: number;
    sx?: object;
  }[];
  rows: {
    id: string;
    cells: CellType[];
  }[];
  footer?: string;
};

function SimpleTable({ title, headers, rows, footer }: Props) {
  return (
    <Container>
      {title && (
        <Typography component="p" variant="body1">
          {title}
        </Typography>
      )}
      <TableContainer
        sx={{ border: "1px solid #dee5e7", borderRadius: "4px", flex: 1 }}
      >
        <Table aria-label="simple table" sx={{ height: "100%" }}>
          {headers && (
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell
                    key={header.text}
                    colSpan={header.colSpan || 1}
                    sx={{ ...tableHeadCellStyle, ...header.sx }}
                  >
                    {header.text}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                {row.cells.map((cell) => (
                  <TableCell
                    key={cell.id}
                    sx={{
                      ...cellStyle,
                      width: `${100 / row.cells.length}%`,
                      ...cell.sx,
                    }}
                    colSpan={cell.colSpan || 1}
                    align={cell.align || "left"}
                  >
                    {cell.linkTo ? (
                      <Link to={cell.linkTo}>{cell.text}</Link>
                    ) : (
                      cell.text
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {footer && (
        <Typography component="p" variant="body1">
          {footer}
        </Typography>
      )}
    </Container>
  );
}

export default SimpleTable;
