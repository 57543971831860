import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { DialogModalType } from "redux/types";

type Props = {
  open: boolean;
};
export default function DialogModal({
  open,
  title = "",
  text = "",
  handleClose,
  color,
}: Props & DialogModalType) {
  let titleSx = {};
  if (color) {
    titleSx = { ...titleSx, color };
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      {title && (
        <DialogTitle sx={titleSx} id="alert-dialog-title">
          {title}
        </DialogTitle>
      )}
      {text && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
