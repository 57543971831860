import React, { ChangeEvent, useState } from "react";
import { FormControl, RadioGroup, Typography } from "@mui/material";
import { RadioButton } from "components";

type Props = {
  title: string;
  radioButtons: { id: string; label: string }[];
  optionsInVertical?: boolean;
};

export default function RadioButtonGroup({
  title,
  radioButtons = [],
  optionsInVertical,
}: Props) {
  if (radioButtons.length === 0) {
    return null;
  }

  const [value, setValue] = useState(radioButtons[0].id);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const groupStyle = {
    display: "flex",
    gap: "20px",
  };

  return (
    <FormControl>
      <Typography variant="body1" component="p" sx={{ marginBottom: "10px" }}>
        {title}
      </Typography>
      <RadioGroup
        defaultValue={radioButtons[0].id}
        name="radio-buttons-group"
        sx={groupStyle}
        row={!optionsInVertical}
        onChange={handleChange}
      >
        {radioButtons.map(({ id, label }) => (
          <RadioButton
            key={id}
            value={id}
            label={label}
            isButtonStyle
            selected={value === id}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
