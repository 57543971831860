import React, { useState, useEffect } from "react";
import { Typography, Alert } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Title } from "react-head";
import { useNavigate } from "react-router-dom";
import {
  PageTitle,
  Dropdown,
  Button,
  Loading,
  CardButton,
  DialogModal,
  TextInput,
} from "components";
import { RowOfElements } from "pages/styles";
import { mediaQueries } from "utils";
import {
  useAddTradingAccountMutation,
  useGetTradingAccountsCurrenciesQuery,
} from "redux/features/tradingaccountsapi/tradingAccountsApi";
import {
  DialogModalType,
  AfterActionFeedbackType,
  PlatformType,
} from "redux/types";
import { confirmPasswordValidation, passwordValidation } from "pages/utils";
import { OptionType } from "components/types";

type TradingPlatformType = {
  id: number;
  label: string;
  type: string;
};

type Props = {
  isDemo?: boolean;
};

export default function AddTradingAccount({ isDemo = false }: Props) {
  const theme = useTheme();
  const navigate = useNavigate();

  const [data, setData] = useState<PlatformType[]>([]);
  const [tradingPlatforms, setTradingPlatforms] = useState<
    TradingPlatformType[]
  >([]);
  const [platform, setPlatform] = useState({ id: 0, type: "" });
  const [currencies, setCurrencies] = useState<OptionType[]>([]);
  const [currency, setCurrency] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<
    string | React.ReactElement
  >("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState<string | React.ReactElement>("");
  const [canValidate, setCanValidate] = useState(false);
  const [addFeedback, setAddFeedback] = useState<DialogModalType>();

  // UNCOMMENT THIS NEXT LINE IF WE NEED TO SEND THE USER ID
  // const userData = parseJwt(localStorage.getItem("id_token") as string);

  const {
    data: accounts,
    isFetching: isFetchingAccounts,
    error: errorAccounts,
  } = useGetTradingAccountsCurrenciesQuery({
    brandId: process.env.REACT_APP_WHITELABEL as string,
  });

  const resetPage = () => {
    setPassword("");
    setConfirmPassword("");
    setCanValidate(false);
    setError("");
  };

  useEffect(() => {
    if (accounts) {
      setData(isDemo ? accounts.DEMO : accounts.LIVE);
    }
  }, [accounts, isDemo]);

  useEffect(() => {
    resetPage();
    if (!isFetchingAccounts) {
      setTradingPlatforms(
        data.map((res) => ({
          id: res.id,
          label: res.name,
          type: res.type,
        }))
      );
    }
  }, [data]);

  useEffect(() => {
    if (tradingPlatforms && tradingPlatforms.length > 0) {
      const { id, type } = data[0];
      setPlatform({ id, type });
    }
  }, [tradingPlatforms]);

  useEffect(() => {
    if (platform.type) {
      setShowPassword(platform.type === "MT4");
      const currentPlatform = data.find((res) => res.id === platform.id);
      if (currentPlatform) {
        const newCurrencies = currentPlatform.currencies.map((res) => ({
          value: res,
          label: res,
        }));
        setCurrencies(newCurrencies);
        if (newCurrencies && newCurrencies.length > 0) {
          setCurrency(newCurrencies[0].value);
        }
      }
    }
  }, [platform]);

  useEffect(() => {
    const validation = passwordValidation(password);
    setPasswordErrorMessage(validation.isValid ? "" : validation.errorHelper);
  }, [password]);

  useEffect(() => {
    const validation = confirmPasswordValidation(confirmPassword, password);
    setConfirmPasswordErrorMessage(
      validation.isValid ? "" : validation.errorHelper
    );
  }, [confirmPassword, password]);

  // Add api
  const [addTradingAccount, { isLoading: isLoadingAddTradingAccount }] =
    useAddTradingAccountMutation();

  const onAddTradingAccountHandler = async () => {
    setCanValidate(showPassword);

    if (
      !showPassword ||
      (!passwordErrorMessage && !confirmPasswordErrorMessage)
    ) {
      const res = (await addTradingAccount({
        brandId: process.env.REACT_APP_WHITELABEL as string,
        body: {
          backend_id: platform.id,
          account_type: platform.type,
          currency,
          mt4_password: password,
          isDemo,
        },
      })) as { data?: AfterActionFeedbackType; error?: Error };

      const defaultErrorText = `There was a problem adding a new ${
        isDemo ? "demo" : "trading"
      } account. Please try again later.`;

      if (res?.error) {
        setAddFeedback({
          title: "FAIL",
          text: defaultErrorText,
          handleClose: () => setAddFeedback(undefined),
          color: theme.palette.error.main,
        });
      } else if (res?.data?.error) {
        setAddFeedback({
          title: "FAIL",
          text: res.data.error || defaultErrorText,
          handleClose: () => setAddFeedback(undefined),
          color: theme.palette.error.main,
        });
      } else {
        setAddFeedback({
          title: "SUCCESS",
          text: `The ${
            isDemo ? "demo" : "trading"
          } account was created with success.`,
          handleClose: () => navigate(`/accounts`),
          color: theme.palette.success.main,
        });
      }
    }
  };

  const displayContent = () => {
    if (!addFeedback) {
      if (errorAccounts) {
        return (
          <Alert severity="error">
            There was a problem retrieving the trading platforms. Please try
            again later.
          </Alert>
        );
      }

      if (error) {
        return <Alert severity="warning">{error}</Alert>;
      }

      if (isFetchingAccounts) {
        return <Loading />;
      }

      if (data.length === 0) {
        return (
          <Alert severity="warning">
            {`Sorry, you cannot create more ${
              isDemo ? "demo" : "trading"
            } accounts.`}
          </Alert>
        );
      }
    }

    return (
      <>
        <Typography variant="body1" component="p">
          {`Choose your desired trading platform and Currency settings${
            isDemo ? " for your demo account" : ""
          }.`}
        </Typography>
        <RowOfElements>
          {tradingPlatforms.map((tp) => (
            <CardButton
              key={tp.id}
              text={tp.label}
              selected={platform.id === tp.id}
              onClick={() => setPlatform({ id: tp.id, type: tp.type })}
            />
          ))}
        </RowOfElements>
        <Dropdown
          label="Currency"
          value={currency}
          options={currencies}
          onChange={setCurrency}
          extraStyle={{ [mediaQueries[1]]: { maxWidth: "480px" } }}
        />
        {showPassword && (
          <>
            <TextInput
              title="Password"
              value={password}
              size="small"
              required
              error={canValidate && !!passwordErrorMessage}
              helperText={passwordErrorMessage}
              onChange={setPassword}
              sx={{
                maxWidth: "100%",
                [mediaQueries[1]]: { maxWidth: "480px" },
              }}
              type="password"
            />
            <TextInput
              title="Confirm password"
              value={confirmPassword}
              size="small"
              required
              error={canValidate && !!confirmPasswordErrorMessage}
              helperText={confirmPasswordErrorMessage}
              onChange={setConfirmPassword}
              sx={{
                maxWidth: "100%",
                [mediaQueries[1]]: { maxWidth: "480px" },
              }}
              type="password"
            />
          </>
        )}
        <Button
          sx={{
            width: "100%",
            [mediaQueries[1]]: { width: "initial" },
          }}
          text={`Create a new ${isDemo ? "demo" : "trading"} account`}
          onClick={onAddTradingAccountHandler}
          disabled={
            !currency || (showPassword && (!password || !confirmPassword))
          }
        />
        {isLoadingAddTradingAccount && <Loading />}
        {addFeedback && (
          <DialogModal
            open
            title={addFeedback.title}
            text={addFeedback.text}
            handleClose={addFeedback.handleClose}
            color={addFeedback.color}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Title>Add Trading Accts</Title>
      <PageTitle title={`ADD A NEW ${isDemo ? "DEMO" : "TRADING"} ACCOUNT`} />
      {displayContent()}
    </>
  );
}
