import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { Routes, Route, useLocation } from "react-router-dom";
import { HeadProvider, Title, Link } from "react-head";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import { Home, Login, ForgotPassword, Signup, MobileDeposit } from "pages";
import { TopHeader, Footer, Loading } from "components";
import { mediaQueries } from "utils";
import {
  fetchBrandInfo,
  PortalBrandStateType,
} from "redux/features/portalbrand/portalBrandSlice";
import { isTDBrand } from "pages/utils";

const Container = styled.div`
  * {
    box-sizing: border-box;
  }

  height: 100vh;
  overflow-x: hidden;

  ${mediaQueries[1]} {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 100px 1fr auto;
  }

  .topnav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 60px;

    ${mediaQueries[1]} {
      position: relative;
      grid-column: 1 / span 2;
      grid-row: 1 / 2;
      height: initial;
    }
  }

  ${mediaQueries[1]} {
    .footer {
      grid-column: 1 / 3;
      grid-row: 3 / 4;
    }
  }
`;

const Content = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 60px);

  ${mediaQueries[1]} {
    margin-top: 0;
    height: 100%;
  }
`;

const brandId = process.env.REACT_APP_WHITELABEL as string;

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBrandInfo());

    // TD365AU and TD365 Beacon
    if (isTDBrand()) {
      const script1 = document.createElement("script");

      const scriptText1 = document.createTextNode(
        '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});'
      );

      script1.appendChild(scriptText1);
      document.head.appendChild(script1);

      const script2 = document.createElement("script");

      const scriptText2 = document.createTextNode(
        brandId === "TD365AU"
          ? "window.Beacon('init', 'f3865b5a-0135-4afe-b3c7-26e195cd7263')"
          : "window.Beacon('init', '3d37a9dc-541f-4c4a-b8ef-2a1fc7157fcb')"
      );

      script2.appendChild(scriptText2);
      document.head.appendChild(script2);
    }
  }, []);

  const { loading, info } = useSelector(
    (state: { portalbrand: PortalBrandStateType }) => state.portalbrand
  );

  const location = useLocation();

  if (loading) {
    return <Loading />;
  }

  const themeStyle = {
    palette: {
      success: {
        main: "#008f00",
        dark: "#005c00",
      },
    },
  } as Theme;
  if (info?.palette?.primary) {
    themeStyle.palette.primary = info.palette.primary;
  }
  if (info?.palette?.secondary) {
    themeStyle.palette.secondary = info.palette.secondary;
  }

  const theme = createTheme(themeStyle);

  const displayFavicon = () => {
    const faviconName =
      brandId === "TD365" || brandId === "TD365UK" ? "TD365.com_Favicon" : "";
    if (faviconName) {
      return <Link rel="icon" href={`/assets/images/${faviconName}.png`} />;
    }

    return null;
  };

  if (location.pathname.includes("mobiledeposit")) {
    return (
      <HeadProvider>
        <Title>{process.env.REACT_APP_WHITELABEL}</Title>
        {displayFavicon()}
        <Routes>
          <Route
            path="/mobiledeposit/:tradingAccount/"
            element={<MobileDeposit />}
          />
        </Routes>
      </HeadProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <HeadProvider>
        <Title>{process.env.REACT_APP_WHITELABEL}</Title>
        {displayFavicon()}
        <Container className="page-container">
          <TopHeader className="topnav" />
          <Content>
            <Routes>
              <Route path="/signup/" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/*" element={<Home />} />
            </Routes>
            <Footer className="footer" />
          </Content>
        </Container>
      </HeadProvider>
    </ThemeProvider>
  );
}

export default App;
