import { configureStore } from "@reduxjs/toolkit";
import { userProfileApi } from "redux/features/userprofileapi/userProfileApi";
import { signupApi } from "redux/features/signupapi/signupApi";
import { loginApi } from "redux/features/loginapi/loginApi";
import { tradingAccountsApi } from "redux/features/tradingaccountsapi/tradingAccountsApi";
import portalBrandReducer from "./features/portalbrand/portalBrandSlice";

export const store = configureStore({
  reducer: {
    portalbrand: portalBrandReducer,
    [userProfileApi.reducerPath]: userProfileApi.reducer,
    [signupApi.reducerPath]: signupApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [tradingAccountsApi.reducerPath]: tradingAccountsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      userProfileApi.middleware,
      signupApi.middleware,
      loginApi.middleware,
      tradingAccountsApi.middleware
    );
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
