import React from "react";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import { mediaQueries } from "utils";

const Container = styled(Typography)`
  font-size: 18px;
  ${mediaQueries[1]} {
    font-size: 26px;
  }
` as typeof Typography;

type Props = {
  title: string;
};

export default function PageTitle({ title }: Props) {
  return (
    <Container component="h2" variant="h2">
      {title}
    </Container>
  );
}
