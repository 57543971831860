import React from "react";
import { Button as ButtonMui, ButtonProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";

type Props = {
  text: string;
  backgroundColor?: string;
};

function Button(props: ButtonProps & Props) {
  const theme = useTheme();
  const { text, sx, variant = "contained", backgroundColor, ...rest } = props;

  let styling: ButtonProps["sx"] = { textTransform: "none", ...sx };
  if (backgroundColor) {
    styling = { ...styling, backgroundColor, ":hover": { backgroundColor } };
  }

  if (theme.palette.primary && variant !== "text" && !backgroundColor) {
    styling = {
      ...styling,
      backgroundColor: theme.palette.success.main,
      ":hover": { backgroundColor: theme.palette.success.dark },
    };
  }

  return (
    <ButtonMui
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      variant={variant}
      sx={styling}
    >
      {text}
    </ButtonMui>
  );
}

export default Button;
