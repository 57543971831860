/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker as BasicDatePicker } from "@mui/lab";
import { DatePickerView } from "@mui/lab/DatePicker/shared";

type DatePickerProps = {
  error?: boolean;
  errorHelperText?: string | JSX.Element;
  label?: string;
  value: Date | null;
  maxDate?: Date;
  minDate?: Date;
  sx?: Record<string, string> | object;
  openTo?: "day" | "month" | "year";
  onChange: (newValue: Date | null) => void;
};

export default function DatePicker({
  error,
  errorHelperText = "Error",
  label,
  value = null,
  onChange,
  sx,
  maxDate,
  minDate,
  openTo = "day",
}: DatePickerProps) {
  const [currentView, setCurrentView] = useState<DatePickerView>();
  const [open, setOpen] = useState(false);

  const onDateSelect = (val: Date | null) => {
    if (currentView === "day" || !open) {
      onChange(val);
      setCurrentView("year");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <BasicDatePicker
        mask="__/__/____"
        inputFormat="dd/MM/yyyy"
        label={label}
        value={value}
        onChange={onDateSelect}
        maxDate={maxDate}
        minDate={minDate}
        openTo={openTo}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onViewChange={setCurrentView}
        allowSameDateSelection
        OpenPickerButtonProps={{ tabIndex: -1 }}
        renderInput={(params) => (
          <TextField
            size="small"
            sx={sx}
            {...params}
            error={error}
            helperText={error ? errorHelperText : ""}
          />
        )}
      />
    </LocalizationProvider>
  );
}
