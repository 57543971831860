/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { Title } from "react-head";
import { TextInput, Button } from "components";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { FormPageWrapper, FormWrapper, RowOfElements } from "pages/styles";
import { FIELD_REQUIRED_MESSAGE } from "utils";
import { useSelector } from "react-redux";
import { PortalBrandStateType } from "redux/features/portalbrand/portalBrandSlice";
import { resetPasswordWithAuth0 } from "redux/features/signupapi/signupApi";

export default function ForgotPassword() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [userEmail, setUserEmail] = useState("");
  const [userEmailErrorMessage, setUserEmailErrorMessage] = useState("");
  const [isComplete, setIsComplete] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      navigate("/");
    }
  }, []);

  const { steps } = useSelector(
    (state: { portalbrand: PortalBrandStateType }) => state.portalbrand.info
  );

  useEffect(() => {
    setUserEmailErrorMessage(userEmail ? "" : FIELD_REQUIRED_MESSAGE);
  }, [userEmail]);

  const onUsernameChange = (value: string) => {
    setUserEmail(value);
  };

  const handleResetPassword = async () => {
    try {
      await resetPasswordWithAuth0(userEmail);
      setIsComplete(true);
    } catch (error) {
      setIsComplete(false);
      setIsSubmitError(true);
    }
    setIsFormDirty(true);
  };

  return (
    <FormPageWrapper>
      <Title>Forgot Password</Title>
      <Typography sx={{ marginBottom: "15px" }} variant="h5" component="h1">
        Forgot your password?
      </Typography>
      <FormWrapper theme={theme}>
        {!isComplete && (
          <TextInput
            id="login_userid"
            title="Email address"
            value={userEmail}
            size="small"
            required
            error={isFormDirty && !!userEmailErrorMessage}
            helperText={userEmailErrorMessage}
            onChange={onUsernameChange}
          />
        )}

        {isComplete && (
          <Typography
            variant="body1"
            sx={{ color: "primary.main", fontSize: 12 }}
          >
            If your email is linked to an account, you will shortly receive a
            password reset request.
          </Typography>
        )}

        {isSubmitError && (
          <Typography variant="body1" sx={{ color: "red", fontSize: 12 }}>
            There seems to have been a problem. Please check your email was
            valid and if the problem persists, contact Support.
          </Typography>
        )}

        {!isComplete && (
          <Button
            type="button"
            onClick={handleResetPassword}
            text="Restore password"
          />
        )}
        <RowOfElements justifyContent="space-between">
          <Button
            text="Log in"
            variant="text"
            sx={{ color: theme.palette.primary.main }}
            onClick={() => navigate("/login")}
          />
          {steps.length > 0 && (
            <Button
              text="Don't have an account?"
              variant="text"
              sx={{ color: theme.palette.primary.main }}
              onClick={() => navigate("/signup")}
            />
          )}
        </RowOfElements>
      </FormWrapper>
    </FormPageWrapper>
  );
}
