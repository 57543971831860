import React from "react";
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridSortModel,
  GridFilterModel,
  GridCallbackDetails,
} from "@mui/x-data-grid";
import styled from "@emotion/styled";

type Props = {
  page?: number;
  rowCount?: number;
  paginationMode?: "client" | "server";
  rows: GridRowModel[];
  columns: GridColDef[];
  pageSize?: number;
  rowsPerPageOptions?: number[];
  disableSelectionOnClick?: boolean;
  sortingMode?: "client" | "server";
  onSortModelChange?: (
    model: GridSortModel,
    details: GridCallbackDetails
  ) => void;
  filterMode?: "client" | "server";
  onFilterModelChange?: (
    model: GridFilterModel,
    details: GridCallbackDetails
  ) => void;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
  height?: number;
  minHeight?: number;
  noRowsMessage?: string;
};

function NoRowsMessage(message: string) {
  const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `;

  return <Container>{message}</Container>;
}

function DataGridTable({
  page = 0,
  rows,
  columns,
  rowCount = 50,
  paginationMode = "server",
  pageSize = 10,
  rowsPerPageOptions = [10, 20, 50],
  disableSelectionOnClick,
  sortingMode = "client",
  onSortModelChange = () => {},
  filterMode = "client",
  onFilterModelChange = () => {},
  onPageChange,
  onPageSizeChange,
  height,
  minHeight,
  noRowsMessage = "No rows",
}: Props) {
  const DataGridWrapper = React.useMemo(
    () => styled.div`
      width: 100%;
      height: ${height ? `${height}px` : "100%"};
      min-height: ${minHeight ? `${minHeight}px` : "initial"};

      .css-pay28z-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
        display: block;
        line-height: 52px;
        height: 52px;
        text-align: left;
      }
    `,
    [height, minHeight]
  );

  return (
    <DataGridWrapper>
      <DataGrid
        page={page}
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        paginationMode={paginationMode}
        pageSize={pageSize}
        rowsPerPageOptions={rowsPerPageOptions}
        disableSelectionOnClick={disableSelectionOnClick}
        sortingMode={sortingMode}
        onSortModelChange={onSortModelChange}
        filterMode={filterMode}
        onFilterModelChange={onFilterModelChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        components={{
          NoRowsOverlay: () => NoRowsMessage(noRowsMessage),
        }}
      />
    </DataGridWrapper>
  );
}

export default DataGridTable;
