import React from "react";
import { InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import styled from "@emotion/styled";
import { DropdownElementType } from "redux/types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  max-width: calc(100vw - 40px);

  div.Mui-disabled {
    cursor: not-allowed;
  }
`;

type Props = {
  label?: string;
  labelIsBold?: boolean;
  value: string;
  options: DropdownElementType[];
  onChange: (val: string) => void;
  extraStyle?: Record<string, unknown>;
};

function Dropdown({
  label,
  labelIsBold,
  value,
  options,
  onChange,
  extraStyle = {},
}: Props) {
  const labelId = `label-${label}`;

  const onChangeHandle = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value);
  };

  return (
    <Container>
      {label && (
        <InputLabel
          sx={{
            color: "#000",
            fontWeight: labelIsBold ? 700 : 400,
            whiteSpace: "initial",
          }}
          id={labelId}
        >
          {label}
        </InputLabel>
      )}
      <Select
        labelId={labelId}
        value={value}
        onChange={onChangeHandle}
        sx={{
          paddingRight: "20px",
          textAlign: "left",
          "&&& > div": { padding: "7px 13px" },
          width: "100%",
          ...extraStyle,
        }}
        disabled={options.length === 0}
      >
        {options.map((option) => (
          <MenuItem
            key={`${labelId}-option-${option.value}`}
            value={option.value}
            sx={{ whiteSpace: "initial" }}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Container>
  );
}

export default Dropdown;
