/* eslint-disable import/prefer-default-export */
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { GridSortModel } from "@mui/x-data-grid";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";

function transformToLink(text: string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => {
    return `<a href="${url}">${url}</a>`;
  });
}

function transformTextWithLinks(text: string) {
  const textSplit = [] as { key: string; display: ReactNode }[];
  text.split(/(<a.*?<\/a>)/).forEach((val, index) => {
    if (val) {
      if (val.startsWith("<a ")) {
        const href = val.match(/href=("|')([^"']*)/);
        const target = val.match(/target=("|')([^"']*)/);
        const innerText = val.match(/>(.*)<\/a>/);
        if (href && innerText) {
          textSplit.push({
            key: `${val.slice(0, 3)}_${index}`,
            display: /^https?:\/\//.test(href[2]) ? (
              <a href={href[2]} target={target ? target[2] : "_self"}>
                {" "}
                {innerText[1]}{" "}
              </a>
            ) : (
              <Link to={href[2]}>{innerText[1]}</Link>
            ),
          });
        }
      } else {
        const newVal = transformToLink(val);
        if (newVal.includes("<a href")) {
          textSplit.push(...transformTextWithLinks(newVal));
        } else {
          textSplit.push({ key: val, display: val });
        }
      }
    }
  });

  return textSplit;
}

const formatDate = (date: Date | null) => {
  if (!date) {
    return "";
  }
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join("-");
};

const formatCurrencyValue = (value: number | string, currencySymbol?: string) =>
  `${currencySymbol ? `${currencySymbol} ` : ""}${Number(value).toLocaleString(
    "en-GB",
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  )}`;

const getTableSortValue = (model: GridSortModel) => {
  if (model.length > 0) {
    if (model[0].sort === "asc") {
      return `&ordering=${model[0].field}`;
    }
    if (model[0].sort === "desc") {
      return `&ordering=-${model[0].field}`;
    }
  }
  return "";
};

const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c: string) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

const checkTokenExpired = (
  error: FetchBaseQueryError | SerializedError | undefined,
  navigate: (loc: string) => void
) => {
  if (error) {
    const { status } = error as { status: number };
    if (status === 401) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("id_token");
      navigate("/login");
    }
  }
};

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts[1].split(";").shift();
  }

  // Try to get the value from local storage if it's not on the cookies
  return localStorage.getItem(name);
};

const setCXDParameter = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const val = urlParams.get("cxd");
  if (val) {
    localStorage.setItem("clickid", val);
  }
};

const isTDBrand = () => {
  const brandId = process.env.REACT_APP_WHITELABEL as string;
  return ["TD365AU", "TD365", "TD365UK"].includes(brandId);
};

export {
  formatDate,
  transformTextWithLinks,
  transformToLink,
  formatCurrencyValue,
  getTableSortValue,
  parseJwt,
  checkTokenExpired,
  getCookie,
  setCXDParameter,
  isTDBrand,
};
