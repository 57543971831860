import styled from "@emotion/styled";
import { Theme } from "@mui/material/styles";
import { mediaQueries } from "utils";

export const PageWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  position: relative;
`;

export const PageContentWrapper = styled.div`
  padding: 15px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const RowOfElements = styled.div<{ justifyContent?: string }>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "start"};
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
`;

export const FormPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0 100px 0;
`;

export const FormWrapper = styled.form`
  display: flex;
  padding: 20px;
  flex-direction: column;
  width: calc(100% - 40px);
  max-width: 370px;
  border-radius: 5px;
  gap: 20px;
  background-color: ${(props: { theme: Theme }) =>
    props.theme.palette.primary.light};

  ${mediaQueries[1]} {
    width: 100%;
  }
`;

export default {
  PageWrapper,
  PageContentWrapper,
  RowOfElements,
  FormPageWrapper,
  FormWrapper,
};
