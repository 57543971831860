/* eslint-disable import/prefer-default-export */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LoginOauthTokenType } from "redux/types";

export const loginApi = createApi({
  reducerPath: "loginapi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_AUTH0_DOMAIN,
  }),
  endpoints: (builder) => ({
    postOauthToken: builder.mutation<
      LoginOauthTokenType,
      {
        body: {
          realm: string;
          client_id: string;
          scope: string;
          grant_type: string;
          username: string;
          password: string;
        };
      }
    >({
      query: ({ body }) => ({
        url: "/oauth/token",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { usePostOauthTokenMutation } = loginApi;
