/* eslint-disable global-require */
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import { Title } from "react-head";
import { useNavigate } from "react-router-dom";
import {
  PageTitle,
  DataGridTable,
  Button,
  Loading,
  DialogModal,
} from "components";
import { useGetTradingAccountsQuery } from "redux/features/tradingaccountsapi/tradingAccountsApi";
import {
  checkTokenExpired,
  formatCurrencyValue,
  getTableSortValue,
  parseJwt,
} from "pages/utils";
import { usePagination, usePaginationApiQuery } from "hooks/usePagination";
import { GridSortModel } from "@mui/x-data-grid";
import { DialogModalType } from "redux/types";

const INITIAL_PAGE_SIZE = 10;

export default function AccountSummary() {
  // Launch
  const [actionFeedback, setActionFeedback] = useState<DialogModalType>();
  const theme = useTheme();
  const navigate = useNavigate();

  const { pagination, setPaginationPage, setPaginationSize } = usePagination({
    page: 0,
    pageSize: INITIAL_PAGE_SIZE,
  });

  const { apiQuery } = usePaginationApiQuery(pagination);

  const [sortString, setSortString] = useState("");
  const getFinalApiQuery = () => {
    return `${apiQuery}${sortString}`;
  };

  const userData = parseJwt(localStorage.getItem("id_token") as string);

  const {
    data = {
      count: 0,
      results: [],
      next: "",
    },
    isFetching,
    error,
  } = useGetTradingAccountsQuery({
    brandId: process.env.REACT_APP_WHITELABEL as string,
    userId: userData["https://finsa/id"],
    params: getFinalApiQuery(),
  });

  useEffect(() => {
    checkTokenExpired(error, navigate);
  }, [error]);

  // This is for handling sort logic on the server side
  const handleSortModelChange = (model: GridSortModel) => {
    setSortString(getTableSortValue(model));
  };

  const columns = [
    {
      field: "platformIcon",
      headerName: "Platform",
      minWidth: 120,
      flex: 1,
      renderCell: (params: { row: { platformIcon: string } }) => (
        <img src={params.row.platformIcon} alt="Platform" />
      ),
    },
    { field: "account", headerName: "Account", width: 100 },
    { field: "backend", headerName: "Backend", width: 120 },
    { field: "currency", headerName: "Currency", width: 100 },
    {
      field: "balance",
      headerName: "Balance",
      width: 130,
      renderCell: (params: {
        row: { balance: string; currencySymbol: string };
      }) => formatCurrencyValue(params.row.balance, params.row.currencySymbol),
    },
    {
      field: "equity",
      headerName: "Equity",
      width: 130,
      renderCell: (params: {
        row: { equity: string; currencySymbol: string };
      }) => formatCurrencyValue(params.row.equity, params.row.currencySymbol),
    },
    {
      field: "actions",
      type: "actions",
      width: 200,
      renderCell: (params: {
        row: {
          id: number;
          button: {
            text: string;
            type: "DOWNLOAD" | "LAUNCH";
            linkTo: string;
          };
        };
      }) => {
        const Buttons = styled.div`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
        `;

        const DownloadButton = styled.a`
          text-decoration: none;
        `;

        const launchError = () => {
          setActionFeedback({
            title: "FAIL",
            text: "There was a problem launching this account's platform. Please try again later.",
            handleClose: () => setActionFeedback(undefined),
            color: theme.palette.error.main,
          });
        };

        const launchOnClick = () => {
          const accessToken = localStorage.getItem("access_token");
          fetch(`${process.env.REACT_APP_API_URL}${params.row.button.linkTo}`, {
            headers: new Headers({
              Authorization: `Bearer ${accessToken}`,
            }),
          })
            .then((response) => response.json())
            .then((json) => {
              if (json.url) {
                window.open(json.url, "_blank");
              } else {
                launchError();
              }
            })
            .catch(() => {
              launchError();
            });
        };

        return (
          <Buttons>
            <Button
              text="Deposit"
              variant="text"
              sx={{ color: theme.palette.primary.main }}
              onClick={() => navigate(`/deposit/${params.row.id}`)}
            />
            {params.row.button.type === "DOWNLOAD" ? (
              <DownloadButton
                href={params.row.button.linkTo}
                download="finsapty4setup"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  text={params.row.button.text}
                  backgroundColor={theme.palette.primary.main}
                />
              </DownloadButton>
            ) : (
              <Button
                text={params.row.button.text}
                backgroundColor={theme.palette.primary.main}
                onClick={launchOnClick}
              />
            )}
          </Buttons>
        );
      },
    },
  ];

  let noRowsMessage = "No rows";
  if (error) {
    noRowsMessage =
      "There was a problem retrieving the trading accounts. Please try again later.";
  } else if (isFetching) {
    noRowsMessage = "";
  }

  return (
    <>
      <Title>Trading Accounts</Title>
      <PageTitle title="ACCOUNT SUMMARY" />
      <DataGridTable
        rows={data.results}
        page={pagination.page}
        pageSize={pagination.pageSize}
        rowCount={data.count || 0}
        sortingMode="server"
        filterMode="server"
        columns={columns}
        disableSelectionOnClick
        onSortModelChange={handleSortModelChange}
        minHeight={400}
        onPageChange={setPaginationPage}
        onPageSizeChange={setPaginationSize}
        noRowsMessage={noRowsMessage}
      />
      {isFetching && <Loading />}
      {actionFeedback && (
        <DialogModal
          open
          title={actionFeedback.title}
          text={actionFeedback.text}
          handleClose={actionFeedback.handleClose}
          color={actionFeedback.color}
        />
      )}
    </>
  );
}
