import React, { useState, useEffect } from "react";
import { Title } from "react-head";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { PageTitle, Dropdown, Loading } from "components";
import {
  useGetDepositDataQuery,
  useGetTradingAccountsQuery,
} from "redux/features/tradingaccountsapi/tradingAccountsApi";
import { DropdownElementType } from "redux/types";
import { mediaQueries } from "utils";
import {
  checkTokenExpired,
  getTradingAccountsOptions,
  parseJwt,
} from "pages/utils";

const getTradingAccount = (accounts: DropdownElementType[], id: string) => {
  if (accounts.length === 0) {
    return "";
  }

  const account = accounts.find((acc) => acc.value === id);

  if (account) {
    return account.value;
  }

  return accounts[0].value;
};

const IFrame = styled.iframe`
  border: none;
`;

export default function DepositFunds() {
  const { id = "" } = useParams();
  const navigate = useNavigate();

  const userData = parseJwt(localStorage.getItem("id_token") as string);

  const {
    data: tradingAccountsData = {
      count: 0,
      results: [],
      next: "",
    },
    isFetching: isFetchingTradingAccounts,
    error: errorTradingAccounts,
  } = useGetTradingAccountsQuery({
    brandId: process.env.REACT_APP_WHITELABEL as string,
    userId: userData["https://finsa/id"],
  });

  useEffect(() => {
    checkTokenExpired(errorTradingAccounts, navigate);
  }, [errorTradingAccounts]);

  const [tradingAccounts, setTradingAccounts] = useState(
    [] as DropdownElementType[]
  );

  useEffect(() => {
    if (
      tradingAccountsData.results &&
      !errorTradingAccounts &&
      !isFetchingTradingAccounts
    ) {
      setTradingAccounts(
        getTradingAccountsOptions(
          tradingAccountsData.results.filter(
            (acc) => acc.accountType === "LIVE"
          ),
          false
        )
      );
    }
  }, [tradingAccountsData]);

  const [selectedAccount, setSelectedAccount] = useState("");

  const {
    data: accountData = { url: "" },
    isFetching: isFetchingAccountData,
    error: errorAccountData,
    refetch,
  } = useGetDepositDataQuery(
    {
      brandId: process.env.REACT_APP_WHITELABEL as string,
      account_id: selectedAccount,
    },
    { skip: !selectedAccount }
  );

  useEffect(() => {
    checkTokenExpired(errorAccountData, navigate);
  }, [errorAccountData]);

  useEffect(() => {
    setSelectedAccount(getTradingAccount(tradingAccounts, id));
  }, [tradingAccounts]);

  useEffect(() => {
    refetch();
  }, [selectedAccount]);

  const displayContent = () => {
    if (isFetchingTradingAccounts) {
      return <Loading />;
    }

    if (errorTradingAccounts) {
      return (
        <Alert severity="error">
          There was a problem retrieving the trading accounts. Please try again
          later.
        </Alert>
      );
    }

    if (
      tradingAccountsData.results.length === 0 &&
      !isFetchingTradingAccounts
    ) {
      return (
        <Alert severity="warning">
          There are no trading accounts available!
        </Alert>
      );
    }

    const displayIframeData = () => {
      if (isFetchingAccountData) {
        return <Loading />;
      }

      if (errorAccountData || !accountData.url) {
        return (
          <Alert severity="error">
            There was a problem retrieving the content for this trading account.
            Please try again later.
          </Alert>
        );
      }

      return (
        <IFrame
          title="Deposit Gateway Iframe"
          id="deposit__iframe"
          height="720px"
          width="100%"
          src={accountData.url}
          data-testid="deposit-gateway-iframe"
          referrerPolicy="origin"
        />
      );
    };

    return (
      <>
        <Dropdown
          label="Please select the trading account you wish to deposit funds to:"
          options={tradingAccounts}
          value={selectedAccount}
          onChange={setSelectedAccount}
          extraStyle={{ [mediaQueries[1]]: { maxWidth: "480px" } }}
        />
        {displayIframeData()}
      </>
    );
  };

  return (
    <>
      <Title>Deposit Funds</Title>
      <PageTitle title="DEPOSIT FUNDS" />
      {displayContent()}
    </>
  );
}
