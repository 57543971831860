import React, { useRef, useState } from "react";
import { Title } from "react-head";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import {
  PageTitle,
  DataGridTable,
  Button,
  Loading,
  DialogModal,
} from "components";
import { usePagination, usePaginationApiQuery } from "hooks/usePagination";
import {
  useUploadDocumentMutation,
  useGetDocumentUploadQuery,
} from "redux/features/userprofileapi/userProfileApi";
import { GridSortModel } from "@mui/x-data-grid";
import { getTableSortValue, parseJwt } from "pages/utils";
import { AfterActionFeedbackType, DialogModalType } from "redux/types";

const INITIAL_PAGE_SIZE = 10;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

export default function DocumentUpload() {
  const theme = useTheme();

  const { pagination, setPaginationPage, setPaginationSize } = usePagination({
    page: 0,
    pageSize: INITIAL_PAGE_SIZE,
  });

  const { apiQuery } = usePaginationApiQuery(pagination);

  const [sortString, setSortString] = useState("");

  const userData = parseJwt(localStorage.getItem("id_token") as string);

  const getFinalApiQuery = () => {
    return `${apiQuery}${sortString}&fullname=${userData.given_name} ${userData.family_name}`;
  };

  const {
    data = {
      count: 0,
      results: [],
      next: "",
    },
    isFetching,
    error,
  } = useGetDocumentUploadQuery({
    brandId: process.env.REACT_APP_WHITELABEL as string,
    userId: userData["https://finsa/id"],
    params: getFinalApiQuery(),
  });

  // This is for handling sort logic on the server side
  const handleSortModelChange = (model: GridSortModel) => {
    setSortString(getTableSortValue(model));
  };

  const columns = [
    { field: "last_modified", headerName: "Last modified", width: 200 },
    { field: "document_name", headerName: "Name", minWidth: 300, flex: 1 },
  ];

  let noRowsMessage = "No rows";
  if (error) {
    noRowsMessage =
      "There was a problem retrieving the documents. Please try again later.";
  } else if (isFetching) {
    noRowsMessage = "";
  }

  // UPLOAD DOCUMENT
  const [actionFeedback, setActionFeedback] = useState<DialogModalType>();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [upload, { isLoading: isLoadingUpload }] = useUploadDocumentMutation();

  const handleUploadDocumentClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const getDocument = async (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    if (target) {
      const file = (target.files as FileList)[0];
      if (file) {
        let fileIsValid = true;
        // File size shouldn't be bigger than 5MB (5242880 bytes)
        if (file.size > 5242880) {
          fileIsValid = false;
          setActionFeedback({
            title: "File is too large",
            text: `The uploaded file is too large (${file.size} bytes). The maximum supported size is 5MB (5242880 bytes)`,
            handleClose: () => setActionFeedback(undefined),
            color: theme.palette.error.main,
          });
        }

        // Only .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .png and .jpeg are supported
        const validFormats = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          "image/png",
          "image/jpeg",
        ];
        if (fileIsValid && !validFormats.includes(file.type)) {
          fileIsValid = false;
          setActionFeedback({
            title: "File format not supported",
            text: `Please upload a file in one of the following formats: .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .png, .jpeg`,
            handleClose: () => setActionFeedback(undefined),
            color: theme.palette.error.main,
          });
        }

        if (fileIsValid) {
          const formData = new FormData();
          formData.append("new_document", file);
          const res = (await upload({
            brandId: process.env.REACT_APP_WHITELABEL as string,
            userId: userData["https://finsa/id"],
            fullname: `${userData.given_name} ${userData.family_name}`,
            body: formData,
          })) as { data?: AfterActionFeedbackType; error?: Error };

          if (res?.error) {
            setActionFeedback({
              title: "FAIL",
              text: "There was a problem uploading this document. Please try again later.",
              handleClose: () => setActionFeedback(undefined),
              color: theme.palette.error.main,
            });
          } else if (res?.data?.error) {
            setActionFeedback({
              title: "FAIL",
              text: res.data.error,
              handleClose: () => setActionFeedback(undefined),
              color: theme.palette.error.main,
            });
          } else {
            setActionFeedback({
              title: "SUCCESS",
              text: "Document uploaded successfully!",
              handleClose: () => setActionFeedback(undefined),
              color: theme.palette.success.main,
            });
          }
        }
      }
      target.value = "";
    }
  };

  return (
    <>
      <Title>Legal Documents</Title>
      <PageTitle title="LEGAL DOCUMENTS" />
      {/* DISPLAY BUTTON WHEN UPLOAD DOCUMENTS FUNCTIONALITY IS ADDED AGAIN */}
      {/* <ButtonWrapper>
        <label htmlFor="upload-document">
          <input
            ref={hiddenFileInput}
            style={{ display: "none" }}
            id="upload-document"
            name="upload-document"
            type="file"
            onChange={getDocument}
          />
          <Button
            text="Upload Document"
            backgroundColor={theme.palette.primary.main}
            onClick={handleUploadDocumentClick}
          />
        </label>
      </ButtonWrapper> */}
      <DataGridTable
        rows={data.results || []}
        page={pagination.page}
        pageSize={pagination.pageSize}
        rowCount={data.count || 0}
        sortingMode="server"
        filterMode="server"
        columns={columns}
        disableSelectionOnClick
        onSortModelChange={handleSortModelChange}
        minHeight={400}
        onPageChange={setPaginationPage}
        onPageSizeChange={setPaginationSize}
        noRowsMessage={noRowsMessage}
      />
      {(isFetching || isLoadingUpload) && <Loading />}
      {actionFeedback && (
        <DialogModal
          open
          title={actionFeedback.title}
          text={actionFeedback.text}
          handleClose={actionFeedback.handleClose}
          color={actionFeedback.color}
        />
      )}
    </>
  );
}
