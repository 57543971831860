import React from "react";
import { Box, List } from "@mui/material";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import PersonIcon from "@mui/icons-material/Person";
import PaidIcon from "@mui/icons-material/Paid";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useMobile } from "hooks/useDisplay";
import { parseJwt } from "pages/utils";
import SubNav from "./SubNav";

type Props = {
  handleMenuOpen?: (val: boolean) => void;
};

export default function SideNav({ handleMenuOpen }: Props) {
  const mobile = useMobile();

  const menus = [
    {
      text: "TRADING ACCOUNTS",
      Icon: <InsertChartOutlinedIcon />,
      subMenus: [
        { title: "Account summary", linkTo: "/accounts" },
        { title: "Add a new trading account", linkTo: "/account-add" },
        { title: "Add a new demo account", linkTo: "/account-add-demo" },
      ],
    },
    {
      text: "MY PROFILE",
      Icon: <PersonIcon />,
      subMenus: [
        { title: "Personal information", linkTo: "/personal" },
        { title: "Change MT4 Password", linkTo: "/change-password" },
        { title: "Legal documents", linkTo: "/legal-documents" },
      ],
    },
    {
      text: "PAYMENTS",
      Icon: <PaidIcon />,
      subMenus: [
        { title: "Deposit funds", linkTo: "/deposit/" },
        { title: "Withdraw funds", linkTo: "/withdraw" },
        // { title: "Internal transfer", linkTo: "/internal" },
        { title: "Payment history", linkTo: "/history" },
      ],
    },
  ];

  const accessToken = localStorage.getItem("access_token");
  const idToken = localStorage.getItem("id_token");

  if (!accessToken) {
    return null;
  }

  if (mobile && idToken) {
    const data = parseJwt(idToken);
    const username = `${data.given_name} ${data.family_name}`;
    menus.unshift({
      text: username,
      Icon: <AccountCircleIcon />,
      subMenus: [],
    });
  }

  return (
    <Box
      sx={(theme) => ({
        width: 300,
        backgroundColor: theme.palette.primary.light,
        height: "100%",
      })}
      role="presentation"
    >
      <List sx={{ paddingTop: mobile ? "0" : "20px" }}>
        {menus.map(({ text, Icon, subMenus }) => (
          <SubNav
            key={text}
            title={text}
            icon={Icon}
            subMenus={subMenus}
            handleMenuOpen={handleMenuOpen}
          />
        ))}
      </List>
    </Box>
  );
}
