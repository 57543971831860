import { TradingAccountType } from "redux/types";
import { formatCurrencyValue } from "pages/utils";

/* eslint-disable import/prefer-default-export */
export const getTradingAccountsOptions = (
  tradingAccounts: TradingAccountType[],
  mustHaveFunds = true
) =>
  tradingAccounts.map((ta) => ({
    value: ta.id.toString(),
    label: `${ta.platform} - ${ta.account} Balance ${formatCurrencyValue(
      ta.balance,
      ta.currencySymbol
    )}`,
    disabled: !ta.balance && mustHaveFunds,
  }));

export const getTradingAccount = (
  accounts: TradingAccountType[],
  selectedAccount: string
) => {
  return accounts.find((acc) => acc.id.toString() === selectedAccount);
};

export const getAccountsWithFunds = (tradingAccounts: TradingAccountType[]) => {
  return tradingAccounts.filter((acc) => Number(acc.balance) > 0);
};
