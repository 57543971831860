/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from "react";
import { breakpoints } from "utils";

export function useMobile() {
  const [mobile, setMobile] = useState(window.innerWidth < breakpoints[1]);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < breakpoints[1]);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return mobile;
}
