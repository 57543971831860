/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/prefer-default-export */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  SignupStepType,
  SignupAdditionalPropertyType,
  SignupPostDataType,
  SignupAuth0DataType,
  CqqObjectType,
} from "redux/types";

export async function resetPasswordWithAuth0(email: string) {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  const urlencoded = new URLSearchParams();
  urlencoded.append("email", email);
  urlencoded.append("connection", "Username-Password-Authentication");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
      // @ts-ignore
      requestOptions
    );
    return res;
  } catch (error) {
    const str = JSON.stringify(error);
    throw Error(str);
  }
}

export const signupApi = createApi({
  reducerPath: "signupapi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  endpoints: (builder) => ({
    getSignupQuestions: builder.query<SignupStepType, { step: string }>({
      query: ({ step }) => `${step}/`,
    }),

    getAdditionalQuestions: builder.query<
      SignupAdditionalPropertyType,
      { brandId: string; country: string }
    >({
      query: ({ brandId, country }) => `/signup/${brandId}/step1${country}/`,
    }),

    resetPassword: builder.query({
      query: () => `/signup/reset-password?email=test_27062022@mailinator.com`,
    }),
    postSignupData: builder.mutation<
      { status: string; alert?: string },
      {
        brandId: string;
        body: SignupPostDataType | Record<string, string | CqqObjectType>;
      }
    >({
      query: ({ brandId, body }) => ({
        url: `/signup/${brandId}/submit/`,
        method: "POST",
        body,
      }),
    }),

    postAuth0: builder.mutation<
      { status: string; access_token: string },
      { brandId: string; body: SignupAuth0DataType }
    >({
      query: ({ brandId, body }) => ({
        url: `/signup/${brandId}/auth0/`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetSignupQuestionsQuery,
  useGetAdditionalQuestionsQuery,
  usePostSignupDataMutation,
  usePostAuth0Mutation,
} = signupApi;
