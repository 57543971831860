/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import {
  TextField,
  InputLabel,
  InputAdornment,
  InputProps,
  IconButton,
  TextFieldProps,
} from "@mui/material";
import styled from "@emotion/styled";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  max-width: calc(100vw - 40px);
`;

type Props = Omit<TextFieldProps, "onChange"> & {
  titleIsBold?: boolean;
  min?: number;
  max?: number;
  onChange: (val: string) => void;
};

function TextInput(props: Props) {
  const {
    id,
    title,
    titleIsBold,
    value,
    onChange,
    min = 0,
    max = null,
    required,
    error,
    helperText = "Required field",
    prefix,
    sx,
    type,
    disabled,
    ...rest
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const onChangeHandler = (event: { target: { value: string } }) => {
    if (onChange) {
      const val = event?.target.value;
      if (type === "number") {
        if (val === "") {
          onChange("0");
        } else if (
          /^-?\d*\.?\d+$/.test(val) &&
          Number(val) >= min &&
          (!max || Number(val) <= max)
        ) {
          onChange(parseFloat(val).toString());
        }
      } else {
        onChange(val);
      }
    }
  };

  // Paste functionality on password type
  let onPaste = {};

  const inputProps = {} as InputProps;
  if (prefix) {
    inputProps.startAdornment = (
      <InputAdornment position="start">{prefix}</InputAdornment>
    );
  }
  if (type === "number") {
    inputProps.inputProps = { min, max };
  } else if (type === "password" && !disabled) {
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword(!showPassword)}
          tabIndex={-1}
        >
          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </InputAdornment>
    );

    // Disable paste functionality on password type
    onPaste = {
      onPaste: (e: { preventDefault: () => void }) => e.preventDefault(),
    };
  }

  return (
    <Container>
      {title && (
        <InputLabel
          sx={{ color: "#000", fontWeight: titleIsBold ? 700 : 400 }}
          htmlFor={id}
        >{`${title}${required ? " *" : ""}`}</InputLabel>
      )}
      <TextField
        {...rest}
        id={id}
        required={required}
        error={error}
        helperText={error ? helperText : ""}
        variant="outlined"
        value={value}
        onChange={onChangeHandler}
        type={type === "password" && showPassword ? "text" : type}
        sx={{ width: "100%", "&&& > div": { backgroundColor: "#fff" }, ...sx }}
        InputProps={inputProps}
        disabled={disabled}
        {...onPaste}
      />
    </Container>
  );
}

export default TextInput;
