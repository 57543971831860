import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Alert } from "@mui/material";
import { Title } from "react-head";
import { useNavigate } from "react-router-dom";
import {
  PageTitle,
  TextInput,
  Button,
  Loading,
  DialogModal,
  Dropdown,
} from "components";
import { OptionType } from "components/types";
import { mediaQueries } from "utils";
import {
  passwordValidation,
  confirmPasswordValidation,
  checkTokenExpired,
  parseJwt,
} from "pages/utils";
import { DialogModalType } from "redux/types";
import {
  useChangePasswordMutation,
  useGetTradingAccountsQuery,
} from "redux/features/tradingaccountsapi/tradingAccountsApi";

export default function ChangePassword() {
  const theme = useTheme();
  const navigate = useNavigate();

  const userData = parseJwt(localStorage.getItem("id_token") as string);

  const {
    data: accounts = {
      count: 0,
      results: [],
      next: "",
    },
    isFetching: isFetchingAccounts,
    error: errorPlatforms,
  } = useGetTradingAccountsQuery({
    brandId: process.env.REACT_APP_WHITELABEL as string,
    userId: userData["https://finsa/id"],
  });

  useEffect(() => {
    checkTokenExpired(errorPlatforms, navigate);
  }, [errorPlatforms]);

  const [mt4Accounts, setMt4Accounts] = useState<OptionType[]>([]);

  useEffect(() => {
    const mt4 = accounts.results
      .filter((acc) => acc.platform === "MT4")
      .map((acc) => ({
        value: acc.id.toString(),
        label: `${acc.platform} - ${acc.account} ${acc.accountType}`,
      }));
    setMt4Accounts(mt4);
  }, [accounts]);

  const [selectedAccount, setSelectedAccount] = useState<string>("");

  useEffect(() => {
    setSelectedAccount(mt4Accounts[0]?.value || "");
  }, [mt4Accounts]);

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState<
    string | React.ReactElement
  >("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState<string | React.ReactElement>("");
  const [canValidate, setCanValidate] = useState(false);

  useEffect(() => {
    const validation = passwordValidation(newPassword);
    setNewPasswordErrorMessage(
      validation.isValid ? "" : validation.errorHelper
    );
  }, [newPassword]);

  useEffect(() => {
    const validation = confirmPasswordValidation(confirmPassword, newPassword);
    setConfirmPasswordErrorMessage(
      validation.isValid ? "" : validation.errorHelper
    );
  }, [confirmPassword, newPassword]);

  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const [changePasswordFeedback, setChangePasswordFeedback] =
    useState<DialogModalType>();

  const onSave = async () => {
    setCanValidate(true);

    if (!newPasswordErrorMessage && !confirmPasswordErrorMessage) {
      const res = (await changePassword({
        brandId: process.env.REACT_APP_WHITELABEL as string,
        body: {
          account_id: selectedAccount,
          new_password: newPassword,
        },
      })) as { data: { status: string; msg: string }; error?: Error };

      if (res?.error) {
        checkTokenExpired(res.error, navigate);
        setChangePasswordFeedback({
          title: "FAIL",
          text: "There was a problem changing your password. Please try again later.",
          handleClose: () => setChangePasswordFeedback(undefined),
          color: theme.palette.error.main,
        });
      } else {
        const isSuccess = res.data.status === "success";
        setChangePasswordFeedback({
          title: res.data.status.toUpperCase(),
          text: isSuccess
            ? "Your password was changed with success."
            : res.data.msg,
          handleClose: () =>
            isSuccess
              ? navigate(`/personal`)
              : setChangePasswordFeedback(undefined),
          color: isSuccess
            ? theme.palette.success.main
            : theme.palette.error.main,
        });
      }
    }
  };

  const displayContent = () => {
    if (errorPlatforms) {
      return (
        <Alert severity="error">
          There was a problem retrieving the trading platforms. Please try again
          later.
        </Alert>
      );
    }

    if (isFetchingAccounts) {
      return <Loading />;
    }

    if (mt4Accounts.length === 0) {
      return <Alert severity="warning">You have no MT4 accounts.</Alert>;
    }

    return (
      <>
        <Dropdown
          label="Please select the MT4 trading account:"
          options={mt4Accounts}
          value={selectedAccount}
          onChange={(newAccount) => setSelectedAccount(newAccount)}
          extraStyle={{ [mediaQueries[1]]: { maxWidth: "480px" } }}
        />
        <TextInput
          title="New password"
          titleIsBold
          value={newPassword}
          size="small"
          required
          error={canValidate && !!newPasswordErrorMessage}
          helperText={newPasswordErrorMessage}
          onChange={setNewPassword}
          sx={{
            maxWidth: "100%",
            [mediaQueries[1]]: { maxWidth: "480px" },
          }}
          type="password"
        />
        <TextInput
          title="Confirm new password"
          titleIsBold
          value={confirmPassword}
          size="small"
          required
          error={canValidate && !!confirmPasswordErrorMessage}
          helperText={confirmPasswordErrorMessage}
          onChange={setConfirmPassword}
          sx={{
            maxWidth: "100%",
            [mediaQueries[1]]: { maxWidth: "480px" },
          }}
          type="password"
        />
        <Button
          sx={{
            width: "100%",
            [mediaQueries[1]]: { width: "initial" },
          }}
          text="Save changes"
          onClick={onSave}
        />
        {isLoading && <Loading />}
        {changePasswordFeedback && (
          <DialogModal
            open
            title={changePasswordFeedback.title}
            text={changePasswordFeedback.text}
            handleClose={changePasswordFeedback.handleClose}
            color={changePasswordFeedback.color}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Title>Change Password</Title>
      <PageTitle title="CHANGE MT4 PASSWORD" />
      {displayContent()}
    </>
  );
}
