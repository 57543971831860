/* eslint-disable import/prefer-default-export */
import React, { Fragment } from "react";
import { FIELD_REQUIRED_MESSAGE } from "utils";
import { subYears, isBefore } from "date-fns";
import styled from "@emotion/styled";

const ErrorSpan = styled.span`
  font-weight: 700;
`;

const getValidationResult = (brokenRules: string[]) => {
  return {
    isValid: brokenRules.length === 0,
    errorHelper: (
      <>
        {brokenRules.map((rule) => (
          <Fragment key={rule}>
            <ErrorSpan>{rule}</ErrorSpan>
            <br />
          </Fragment>
        ))}
      </>
    ),
  };
};

export const passwordValidation = (password: string, currentPassword = "") => {
  const passwordBrokenRules = [];

  if (password.length === 0) {
    passwordBrokenRules.push(FIELD_REQUIRED_MESSAGE);
  } else if (password === currentPassword) {
    passwordBrokenRules.push(
      "New password must be different than current password"
    );
  } else {
    if (password.length < 8) {
      passwordBrokenRules.push("Must have at least 8 characters");
    }

    if (!(/[a-z]/.test(password) && /[A-Z]/.test(password))) {
      passwordBrokenRules.push("Must contain upper and lower case characters");
    }

    if (!(/[0-9]/.test(password) && /[!@#$%^&*]/.test(password))) {
      passwordBrokenRules.push("Must contain a number and a symbol (!@#$%^&*)");
    }

    if (!/^[ A-Za-z0-9!@#$%^&* ]*$/.test(password)) {
      passwordBrokenRules.push(
        "Must not contain special characters (Æ, Ø, Å, Á, etc.)"
      );
    }
  }

  return getValidationResult(passwordBrokenRules);
};

export const confirmPasswordValidation = (
  confirmPassword: string,
  password: string
) => {
  const passwordBrokenRules = [];

  if (confirmPassword.length === 0) {
    passwordBrokenRules.push(FIELD_REQUIRED_MESSAGE);
  } else if (confirmPassword !== password) {
    passwordBrokenRules.push("The password confirmation does not match.");
  }

  return getValidationResult(passwordBrokenRules);
};

export const emailValidation = (email: string, emailExist: boolean) => {
  const brokenRules = [];
  if (email.length === 0) {
    brokenRules.push(FIELD_REQUIRED_MESSAGE);
  }

  if (emailExist) {
    brokenRules.push("User email already exist please use a new one.");
  }
  if (!/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
    brokenRules.push("Wrong email format.");
  }
  return getValidationResult(brokenRules);
};

export const dateOfBirthValidation = (value: string) => {
  const brokenRules: string[] = [];

  const dobRegexp =
    // eslint-disable-next-line no-useless-escape
    /^\d{4}\-(((0)[1-9])|((1)[012]))\-(((0)[1-9])|(([1-2])[0-9])|3[01])$/;

  if (value.length === 0) {
    brokenRules.push(FIELD_REQUIRED_MESSAGE);
  } else {
    if (!dobRegexp.test(value)) {
      brokenRules.push("Invalid date format, please use dd/mm/yyyy.");
    }
    const maximumValidDate = new Date(subYears(Date.now(), 18));
    if (isBefore(maximumValidDate, new Date(value))) {
      brokenRules.push("You must be at least 18 or over.");
    }
  }

  return getValidationResult(brokenRules);
};

export const telephoneValidation = (value: string) => {
  const brokenRules: string[] = [];
  const compactVal = value.replace(/[() .-]/g, "").trim();
  const phoneRegex = /^\+[0-9]{10,16}$/;
  if (!phoneRegex.test(compactVal)) {
    brokenRules.push("Please enter a valid phone number with country code");
  }
  return getValidationResult(brokenRules);
};

export const emptyValidation = (value: string, isCheckbox: boolean) => {
  const brokenRules = [];
  if (isCheckbox && value !== "true") {
    brokenRules.push(FIELD_REQUIRED_MESSAGE);
  }
  if (value.length === 0) {
    brokenRules.push(FIELD_REQUIRED_MESSAGE);
  }
  return getValidationResult(brokenRules);
};
