import React from "react";
import { CircularProgress } from "@mui/material";
import styled from "@emotion/styled";

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(4px);
  z-index: 1;
`;

function Loading() {
  return (
    <Container>
      <CircularProgress
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          margin: "auto",
        }}
      />
    </Container>
  );
}

export default Loading;
