/* eslint-disable no-param-reassign */
import { PaletteColor } from "@mui/material";
import { createSlice, Dispatch } from "@reduxjs/toolkit";

// Define a type for the slice state
export type PortalBrandStateType = {
  loading: boolean;
  info: {
    footer: string;
    logo: string;
    palette: {
      primary?: PaletteColor;
      secondary?: PaletteColor;
    };
    steps: string[];
  };
  hasErrors: boolean;
};

// Define the initial state using that type
const initialState: PortalBrandStateType = {
  loading: true,
  info: {
    footer: "",
    logo: "",
    palette: {},
    steps: [],
  },
  hasErrors: false,
};

export const portalBrandSlice = createSlice({
  name: "portalBrand",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    getBrandInfo: (state) => {
      state.loading = true;
    },
    getBrandInfoSuccess: (state, { payload }) => {
      state.info = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getBrandInfoFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { getBrandInfo, getBrandInfoSuccess, getBrandInfoFailure } =
  portalBrandSlice.actions;

export const fetchBrandInfo = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getBrandInfo());

    try {
      const token = localStorage.getItem("access_token");
      const headers: { "Content-Type": string; authorization?: string } = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers.authorization = `Bearer ${token}`;
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_WHITELABEL}/info/`,
        { headers }
      );
      const data = await response.json();

      dispatch(getBrandInfoSuccess(data));
    } catch (error) {
      dispatch(getBrandInfoFailure());
    }
  };
};

export default portalBrandSlice.reducer;
