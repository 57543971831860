import React, { ReactNode } from "react";
import { Modal as ModalMui, Box, ButtonProps, IconButton } from "@mui/material";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "components";
import { useMobile } from "hooks/useDisplay";

const TitleArea = styled.div`
  margin-bottom: 40px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100% - 70px);
`;

const ButtonsArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 40px;
`;

type Props = {
  handleClose: () => void;
  title?: string;
  children?: ReactNode;
  actionButtons?: {
    text: string;
    onClick: () => void;
    color?: ButtonProps["color"];
    variant?: ButtonProps["variant"];
  }[];
};

function Modal({ handleClose, title, children, actionButtons }: Props) {
  const mobile = useMobile();

  return (
    <ModalMui
      open
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } }}
      sx={{ display: "flex" }}
    >
      <Box
        sx={{
          width: mobile ? "calc(100% - 50px)" : "700px",
          margin: "auto",
          backgroundColor: "#fff",
          borderRadius: "6px",
          padding: "15px",
          position: "relative",
          height: mobile ? "100%" : "700px",
          maxHeight: "calc(100% - 50px)",
        }}
      >
        {title && (
          <TitleArea>
            <Typography component="h5" variant="h5">
              {title}
            </Typography>
          </TitleArea>
        )}
        <Content>{children}</Content>
        {actionButtons && actionButtons.length > 0 ? (
          <ButtonsArea>
            {actionButtons.map((button) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Button key={button.text} {...button} />
            ))}
          </ButtonsArea>
        ) : (
          <IconButton
            color="primary"
            component="span"
            sx={{ position: "absolute", top: "10px", right: "10px" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </ModalMui>
  );
}

export default Modal;
