import React from "react";
import { useParams } from "react-router-dom";
import { Title } from "react-head";
import styled from "@emotion/styled";
import { Alert } from "@mui/material";
import { useGetDepositDataQuery } from "redux/features/tradingaccountsapi/tradingAccountsApi";
import { Loading } from "components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const IFrame = styled.iframe`
  border: none;
`;

export default function MobileDeposit() {
  const { tradingAccount = "" } = useParams();

  const {
    data = { url: "" },
    isFetching,
    error,
  } = useGetDepositDataQuery({
    brandId: process.env.REACT_APP_WHITELABEL as string,
    account_id: tradingAccount,
  });

  const displayContent = () => {
    if (isFetching) {
      return <Loading />;
    }

    if (error) {
      return (
        <Alert severity="error">
          There was a problem retrieving the content for this trading account.
          Please try again later.
        </Alert>
      );
    }

    return (
      <IFrame
        id="mobile_deposit__iframe"
        height="100%"
        width="100%"
        src={data.url}
        data-testid="mobile-deposit-iframe"
        referrerPolicy="origin"
      />
    );
  };

  return (
    <>
      <Title>Mobile Deposit</Title>
      <Container>{displayContent()}</Container>
    </>
  );
}
