import React, { useMemo, useState } from "react";
import { Title } from "react-head";
import { PageTitle, DataGridTable, Loading } from "components";
import RefreshIcon from "@mui/icons-material/Refresh";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { usePagination, usePaginationApiQuery } from "hooks/usePagination";
import { useGetPaymentHistoryQuery } from "redux/features/tradingaccountsapi/tradingAccountsApi";
import { GridSortModel } from "@mui/x-data-grid";
import { getTableSortValue, parseJwt } from "pages/utils";

const INITIAL_PAGE_SIZE = 10;

export default function PaymentHistory() {
  const theme = useTheme();

  const { pagination, setPaginationPage, setPaginationSize } = usePagination({
    page: 0,
    pageSize: INITIAL_PAGE_SIZE,
  });

  const { apiQuery } = usePaginationApiQuery(pagination);

  const [sortString, setSortString] = useState("");
  const getFinalApiQuery = () => {
    return `${apiQuery}${sortString}`;
  };

  const userData = parseJwt(localStorage.getItem("id_token") as string);

  const {
    data = {
      count: 0,
      results: [],
      next: "",
    },
    isFetching,
    error,
  } = useGetPaymentHistoryQuery(
    {
      brandId: process.env.REACT_APP_WHITELABEL as string,
      userId: userData["https://finsa/id"],
      params: getFinalApiQuery(),
    },
    { skip: !apiQuery }
  );

  // This is for handling sort logic on the server side
  const handleSortModelChange = (model: GridSortModel) => {
    setSortString(getTableSortValue(model));
  };

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", width: 120 },
      { field: "type", headerName: "Type", width: 150 },
      {
        field: "platform",
        headerName: "Platform",
        width: 150,
        renderCell: (params: { row: { platform: string } }) => (
          <img src={params.row.platform} alt="Platform" />
        ),
      },
      { field: "account", headerName: "Account", width: 120 },
      { field: "date", headerName: "Date", width: 170 },
      { field: "paymentMethod", headerName: "Payment Method", width: 170 },
      { field: "amount", headerName: "Amount", width: 150 },
      {
        field: "status",
        headerName: "Status",
        width: 120,
        renderCell: (params: { row: { status: string } }) => {
          switch (params.row.status) {
            case "Pending":
              return (
                <RefreshIcon sx={{ color: theme.palette.warning.light }} />
              );
            case "Success":
              return <DoneIcon color="success" />;
            case "Rejected":
              return <CloseIcon color="error" />;
            default:
              return null;
          }
        },
      },
    ],
    []
  );

  let noRowsMessage = "No rows";
  if (error) {
    noRowsMessage =
      "There was a problem retrieving the payment history. Please try again later.";
  } else if (isFetching) {
    noRowsMessage = "";
  }

  return (
    <>
      <Title>Payment History</Title>
      <PageTitle title="PAYMENT HISTORY" />
      <DataGridTable
        rows={data.results}
        page={pagination.page}
        pageSize={pagination.pageSize}
        rowCount={data.count || 0}
        sortingMode="server"
        filterMode="server"
        columns={columns}
        disableSelectionOnClick
        onSortModelChange={handleSortModelChange}
        minHeight={400}
        onPageChange={setPaginationPage}
        onPageSizeChange={setPaginationSize}
        noRowsMessage={noRowsMessage}
      />
      {isFetching && <Loading />}
    </>
  );
}
