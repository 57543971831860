import React from "react";
import { Radio, FormControlLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";

type Props = {
  value: string;
  label: string;
  subLabel?: string;
  isButtonStyle?: boolean;
  selected?: boolean;
  sx?: object;
};

const SubLabel = styled.span`
  margin-left: 30px;
  margin-top: -10px;
  color: #999;
`;

export default function RadioButton({
  value,
  label,
  subLabel,
  isButtonStyle,
  selected,
  sx = {},
}: Props) {
  const theme = useTheme();
  const buttonStyle = isButtonStyle
    ? {
        backgroundColor: theme.palette.primary.light,
        border: selected
          ? `1px solid ${theme.palette.primary.dark}`
          : `1px solid ${theme.palette.primary.light}`,
        margin: "0",
        padding: "20px 20px 20px 10px",
        borderRadius: "5px",
        display: "block",
        transition: "border 0.2s",
      }
    : sx;

  return (
    <>
      <FormControlLabel
        sx={buttonStyle}
        control={<Radio />}
        label={label}
        value={value}
        labelPlacement="end"
      />
      <SubLabel>{subLabel}</SubLabel>
    </>
  );
}
