/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, forwardRef, useMemo } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { Theme, useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const LiItem = styled.li`
  position: relative;
  transition: background-color 0.2s;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    transition: background-color 0.2s, width 0.2s;
  }

  &:hover {
    background-color: ${(props: { theme: Theme }) =>
      props.theme.palette.primary.light};

    &::after {
      background-color: ${(props: { theme: Theme }) =>
        props.theme.palette.secondary.main};
      width: 10px;
    }
  }
`;

type Props = {
  icon?: ReactElement;
  primary: string;
  to: string;
  sx?: object;
  primarysx?: object;
  handleMenuOpen?: (val: boolean) => void;
};

export default function SubNavLink({
  icon,
  primary,
  to,
  sx,
  primarysx,
  handleMenuOpen,
}: Props) {
  const theme = useTheme();

  const renderLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to">>(function Link(
        itemProps,
        ref
      ) {
        return (
          <RouterLink
            to={to}
            ref={ref}
            {...itemProps}
            role={undefined}
            onClick={() => handleMenuOpen && handleMenuOpen(false)}
          />
        );
      }),
    [to]
  );

  return (
    <LiItem theme={theme}>
      <ListItem sx={sx} button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText sx={primarysx} primary={primary} />
      </ListItem>
    </LiItem>
  );
}
