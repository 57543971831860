/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
} from "@mui/material";

type CustomSelectProps = {
  error?: boolean;
  value: string | number;
  label?: string;
  sx: object;
  options: { label: string; value: string | number }[];
  onChangeHandler: (value: string | number) => void;
};

function CustomSelect({
  error = false,
  value,
  label,
  options,
  onChangeHandler,
  sx,
  ...restProps
}: CustomSelectProps) {
  return (
    <FormControl error={error} sx={{ width: "100%" }}>
      <InputLabel id={`select-${label}`}>{label}</InputLabel>
      <Select
        {...restProps}
        sx={sx}
        labelId={`select-${label}`}
        value={value}
        label={label}
        onChange={(event) => {
          onChangeHandler(event.target.value);
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>Error:missing filter value</FormHelperText>}
    </FormControl>
  );
}

export default CustomSelect;
