import React from "react";
import styled from "@emotion/styled";
import { Theme, useTheme } from "@mui/material/styles";
import { breakpoints } from "utils";
import { useSelector } from "react-redux";
import { PortalBrandStateType } from "redux/features/portalbrand/portalBrandSlice";

const Container = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${(props: { theme: Theme }) =>
    props.theme.palette.secondary.main};
  color: ${(props: { theme: Theme }) =>
    props.theme.palette.secondary.contrastText};
  a {
    color: ${(props: { theme: Theme }) =>
      props.theme.palette.secondary.contrastText} !important;
  }
`;

const Content = styled.pre`
  max-width: ${breakpoints[3]}px;
  width: 100%;
  padding: 0 10px;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: inherit;
  font-family: inherit;
  margin: 10px 0;
`;

type Props = {
  className: string;
};

export default function Footer({ className }: Props) {
  const theme = useTheme();

  const { footer } = useSelector(
    (state: { portalbrand: PortalBrandStateType }) => state.portalbrand.info
  );

  return (
    <Container theme={theme} className={className}>
      <Content dangerouslySetInnerHTML={{ __html: footer }} />
    </Container>
  );
}
