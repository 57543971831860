import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Title } from "react-head";
import { useNavigate } from "react-router-dom";
import { Typography, Alert } from "@mui/material";
import styled from "@emotion/styled";
import {
  PageTitle,
  Dropdown,
  TextInput,
  Button,
  Loading,
  DialogModal,
} from "components";
import {
  AfterActionFeedbackType,
  DialogModalType,
  TradingAccountType,
} from "redux/types";
import {
  useGetTradingAccountsQuery,
  useWithdrawMutation,
} from "redux/features/tradingaccountsapi/tradingAccountsApi";
import {
  getTradingAccountsOptions,
  getTradingAccount,
  formatCurrencyValue,
  parseJwt,
  getAccountsWithFunds,
} from "pages/utils";
import { FIELD_REQUIRED_MESSAGE, mediaQueries } from "utils";

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;

  ${mediaQueries[1]} {
    flex-direction: row;
    max-width: 480px;
  }
`;

const TextInputWrapper = styled.div`
  width: 100%;

  ${mediaQueries[1]} {
    width: 200px;
  }
`;

export default function WithdrawFunds() {
  const theme = useTheme();
  const navigate = useNavigate();

  const userData = parseJwt(localStorage.getItem("id_token") as string);

  const {
    data: { results: tradingAccounts } = {
      results: [],
    },
    isFetching,
    error: errorTradingAccounts,
  } = useGetTradingAccountsQuery({
    brandId: process.env.REACT_APP_WHITELABEL as string,
    userId: userData["https://finsa/id"],
  });

  const [error, setError] = useState("");

  const [selectedAccount, setSelectedAccount] =
    useState<TradingAccountType | null>();

  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");

  const onSetAmount = (value: string) => {
    setAmount(value);
    if (selectedAccount) {
      if (Number(value) > Number(selectedAccount.balance)) {
        setAmountError(
          "You can't withdraw more than your current account balance"
        );
      } else if (Number(value) <= 0) {
        setAmountError(FIELD_REQUIRED_MESSAGE);
      } else {
        setAmountError("");
      }
    }
  };

  useEffect(() => {
    if (tradingAccounts.length > 0) {
      const accountToSelect = getAccountsWithFunds(tradingAccounts);
      if (accountToSelect.length > 0) {
        setSelectedAccount(accountToSelect[0]);
        setError("");
      } else {
        setError("You have no accounts with funds!");
        setSelectedAccount(null);
      }
    } else if (!isFetching) {
      setError("You have no accounts!");
      setSelectedAccount(null);
    }
  }, [tradingAccounts]);

  useEffect(() => {
    setAmount("");
    setAmountError("");
  }, [selectedAccount]);

  const onAccountChange = (accountId: string) => {
    const account = getTradingAccount(tradingAccounts, accountId);
    if (account) {
      setSelectedAccount(account);
    }
  };

  const [withdraw, { isLoading }] = useWithdrawMutation();
  const [withdrawFeedback, setWithdrawFeedback] = useState<DialogModalType>();

  const onWithdrawHandle = async () => {
    if (selectedAccount && !amountError) {
      const res = (await withdraw({
        brandId: process.env.REACT_APP_WHITELABEL as string,
        accountId: selectedAccount.id,
        body: {
          amount,
        },
      })) as {
        data?: AfterActionFeedbackType;
        error?: { data: { status: { error: string } } };
      };

      if (res?.error) {
        setWithdrawFeedback({
          title: "FAIL",
          text:
            res.error?.data?.status?.error ||
            "There was a problem withdrawing. Please try again later.",
          handleClose: () => setWithdrawFeedback(undefined),
          color: theme.palette.error.main,
        });
      } else if (res?.data?.error) {
        setWithdrawFeedback({
          title: "FAIL",
          text: res.data.error,
          handleClose: () => setWithdrawFeedback(undefined),
          color: theme.palette.error.main,
        });
      } else {
        setWithdrawFeedback({
          title: "SUCCESS",
          text: "The withdraw was processed with success.",
          handleClose: () => navigate(`/history`),
          color: theme.palette.success.main,
        });
      }
    }
  };

  const displayContent = () => {
    if (isFetching) {
      return <Loading />;
    }

    if (errorTradingAccounts) {
      return (
        <Alert severity="error">
          There was a problem retrieving the trading accounts. Please try again
          later.
        </Alert>
      );
    }

    if (error) {
      return <Alert severity="warning">{error}</Alert>;
    }

    return (
      <>
        {getAccountsWithFunds(tradingAccounts).length > 0 && (
          <>
            <Typography variant="body1" component="p">
              Please note that withdrawals may take up to 3-5 business days.
              <br />
              You will be notified via email when your request is processed.
            </Typography>
            <Dropdown
              label="Please select the trading account you wish to withdraw funds from:"
              options={getTradingAccountsOptions(
                getAccountsWithFunds(tradingAccounts)
              )}
              value={selectedAccount ? selectedAccount.id.toString() : ""}
              onChange={onAccountChange}
              extraStyle={{ [mediaQueries[1]]: { maxWidth: "480px" } }}
            />
          </>
        )}
        {selectedAccount && (
          <>
            <Row>
              <Typography
                variant="body1"
                component="p"
                sx={{ fontWeight: 600 }}
              >
                EQUITY
              </Typography>
              <Typography variant="body1" component="p">
                {formatCurrencyValue(
                  selectedAccount.equity,
                  selectedAccount.currencySymbol
                )}
              </Typography>
            </Row>
            <Row>
              <Typography
                variant="body1"
                component="p"
                sx={{ fontWeight: 600 }}
              >
                UNLOCKED BALANCE
              </Typography>
              <Typography variant="body1" component="p">
                {formatCurrencyValue(
                  selectedAccount.balance,
                  selectedAccount.currencySymbol
                )}
              </Typography>
            </Row>
            <Row>
              <Typography
                variant="body1"
                component="p"
                sx={{
                  fontWeight: 600,
                  [mediaQueries[1]]: { marginTop: "8px" },
                }}
              >
                ENTER AMOUNT
              </Typography>
              <TextInputWrapper>
                <TextInput
                  value={amount}
                  size="small"
                  required
                  type="number"
                  error={!!amountError}
                  helperText={amountError}
                  onChange={onSetAmount}
                  prefix={selectedAccount.currency}
                  sx={{ maxWidth: "100%" }}
                />
              </TextInputWrapper>
            </Row>
            <Button
              sx={{
                width: "100%",
                [mediaQueries[1]]: { width: "initial" },
              }}
              text="Request withdrawal"
              onClick={onWithdrawHandle}
              disabled={Number(amount) <= 0 || !!amountError}
            />
          </>
        )}
        {isLoading && <Loading />}
        {withdrawFeedback && (
          <DialogModal
            open
            title={withdrawFeedback.title}
            text={withdrawFeedback.text}
            handleClose={withdrawFeedback.handleClose}
            color={withdrawFeedback.color}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Title>Withdraw Funds</Title>
      <PageTitle title="WITHDRAW FUNDS" />
      {displayContent()}
    </>
  );
}
