/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from "react";
import { PaginationType } from "hooks/types.ds";

export function usePagination(initialState: PaginationType) {
  const [pagination, setPagination] = useState<PaginationType>(initialState);

  const setPaginationPage = (page: number) => {
    setPagination({ ...pagination, page });
  };
  const setPaginationSize = (pageSize: number) => {
    setPagination({ ...pagination, page: 0, pageSize });
  };

  return {
    pagination,
    setPaginationPage,
    setPaginationSize,
  };
}

export function usePaginationApiQuery(pagination: PaginationType) {
  const [apiQuery, setApiQuery] = useState("");

  useEffect(() => {
    const query = `&p=${pagination.page + 1}&p_size=${pagination.pageSize}`;
    setApiQuery(query);
  }, [pagination]);

  return { apiQuery };
}
