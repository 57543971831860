import React, { useState, ReactElement } from "react";
import styled from "@emotion/styled";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
} from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { SubMenuType } from "components/types";
import { SubNavLink } from "components";
import { isTDBrand } from "pages/utils";

const UsernameArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  height: 60px;
  background-color: ${(props: { theme: Theme }) =>
    isTDBrand() ? "#fff" : props.theme.palette.primary.main};
  margin-bottom: 15px;
`;

type Props = {
  title: string;
  icon: ReactElement;
  subMenus: SubMenuType[];
  handleMenuOpen?: (val: boolean) => void;
};

export default function SubNav({
  title,
  icon,
  subMenus = [],
  handleMenuOpen,
}: Props) {
  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  const theme = useTheme();

  const mainColorSx = { color: theme.palette.primary.main };
  const mainTextColorSx = { color: theme.palette.primary.contrastText };

  if (subMenus.length === 0) {
    return (
      <UsernameArea theme={theme}>
        {icon ? (
          <ListItemIcon sx={isTDBrand() ? mainColorSx : mainTextColorSx}>
            {icon}
          </ListItemIcon>
        ) : null}
        <ListItemText
          primary={title}
          sx={isTDBrand() ? mainColorSx : mainTextColorSx}
        />
      </UsernameArea>
    );
  }

  return (
    <>
      <ListItemButton onClick={handleClick} color="primary">
        {icon ? <ListItemIcon sx={mainColorSx}>{icon}</ListItemIcon> : null}
        <ListItemText primary={title} sx={mainColorSx} />
        {open ? (
          <ExpandLess sx={mainColorSx} />
        ) : (
          <ExpandMore sx={mainColorSx} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subMenus.map((menu) => (
            <SubNavLink
              key={`submenu-${menu.title}`}
              to={menu.linkTo}
              primary={menu.title}
              primarysx={{ pl: 8 }}
              handleMenuOpen={handleMenuOpen}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
}
