/* eslint-disable import/prefer-default-export */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  UserType,
  UserPropertyKeyType,
  AfterActionFeedbackType,
  ClientDocumentUploadType,
} from "redux/types";
import { getRtkQueryTagTypes } from "redux/utils";

export const userProfileApi = createApi({
  reducerPath: "userprofileapi",
  tagTypes: getRtkQueryTagTypes(),
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUser: builder.query<UserType, { brandId: string; userId: number }>({
      query: ({ brandId, userId }) => `${brandId}/user/${userId}/`,
      providesTags: () => [{ type: "PersonalDetails" }],
    }),
    updateUser: builder.mutation<
      AfterActionFeedbackType,
      { brandId: string; body: Record<keyof UserPropertyKeyType, string> }
    >({
      query: ({ brandId, body }) => {
        return {
          url: `${brandId}/user/`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: () => [{ type: "PersonalDetails" }],
    }),
    uploadDocument: builder.mutation<
      AfterActionFeedbackType,
      { brandId: string; userId: number; fullname: string; body: FormData }
    >({
      query: ({ brandId, userId, fullname, body }) => ({
        url: `${brandId}/user/${userId}/upload_legal_documents/?fullname=${fullname}`,
        method: "POST",
        body,
      }),
      invalidatesTags: () => [{ type: "DocumentUpload" }],
    }),
    getDocumentUpload: builder.query<
      ClientDocumentUploadType,
      { brandId: string; userId: number; params?: string }
    >({
      query: ({ brandId, userId, params }) =>
        `${brandId}/user/${userId}/legal_documents/${
          params ? `?${params}` : ""
        }`,
      providesTags: () => [{ type: "DocumentUpload" }],
    }),
    postLogin: builder.mutation<
      (string | number | boolean)[],
      { brandId: string; userId: number }
    >({
      query: ({ brandId, userId }) => ({
        url: `/${brandId}/user/${userId}/login/`,
        method: "POST",
        body: {},
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useUpdateUserMutation,
  useUploadDocumentMutation,
  useGetDocumentUploadQuery,
  usePostLoginMutation,
} = userProfileApi;
