import React from "react";
import styled from "@emotion/styled";
import { useTheme, Theme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { mediaQueries } from "utils";

const Container = styled.button`
  position: relative;
  padding: 30px 0;
  width: 100%;
  cursor: pointer;

  ${mediaQueries[1]} {
    width: 200px;
  }

  color: ${(props: { theme: Theme; selected: boolean }) =>
    props.selected ? props.theme.palette.primary.dark : "#333"};
  background-color: ${(props: { theme: Theme; selected: boolean }) =>
    props.selected ? props.theme.palette.primary.light : "#fff"};
  border: 1px solid
    ${(props: { theme: Theme; selected: boolean }) =>
      props.selected ? props.theme.palette.primary.main : "#fff"};
  border-radius: 5px;
  transition: border 0.2s, box-shadow 0.2s, background-color 0.2s;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;

type Props = {
  image?: string;
  text?: string;
  selected: boolean;
  onClick: () => void;
};

export default function CardButton({ image, text, selected, onClick }: Props) {
  const theme = useTheme();

  return (
    <Container
      type="button"
      onClick={onClick}
      theme={theme}
      selected={selected}
    >
      {image && <img src={image} alt="Method" />}
      {text && (
        <Typography variant="body2" component="p">
          {text}
        </Typography>
      )}
    </Container>
  );
}
